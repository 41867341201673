<div  [class]="'app-container app-theme-white closed-sidebar'">
    <app-header></app-header>
    <div class="app-main__outer">
        <div class="app-main__inner">
            <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>