import { Component, OnInit } from '@angular/core';

@Component({
   
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss']
})


export class SpinnerComponent implements OnInit {
 
  ngOnInit() {

  }
}
