<div class="d-flex">
    <div class="header-btn-lg pr-0">
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">
                <div class="widget-content-left">

                    <div class="btn-group" ngbDropdown placement="bottom-right">
                        <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
                            <span>
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <img width="42" src="./assets/images/avatars/1.jpg" alt="" class="rounded-circle">
                                </div>
                            </span>
                        </button>
                        <div ngbDropdownMenu>
                            <button class="dropdown-item" (click)="onLogOut()">Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

