<!-- <div class="notification-wrapper">
    <div class="notification-btn" (click)="openNotification(!showNotification)">
        <i class="fa fa-bell"></i>
    </div>
    <div *ngIf="showNotification" class="notification-content">
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li class="head text-light bg-dark">
                <div class="notification-header">
                    <span>Notifications ({{emailMessages?.length}})</span>
                    <a href="" class="float-right text-light">Mark all as read</a>
                </div>
            </li>
            <li class="notification-box" *ngFor="let item of emailMessages; let even=even" [ngClass]="{'bg-gray': !even }">
                <div class="row">
                    <div class="col-lg-3 col-sm-3 col-3 text-center">
                        <img src="./assets/images/avatars/1.jpg" class="w-50 rounded-circle">
                    </div>
                    <div class="col-lg-8 col-sm-8 col-8">
                        <strong class="text-info">{{item?.fromEmailAddress}}</strong>
                        <div>
                            {{item?.message}}
                        </div>
                        <small class="text-warning">{{item?.formattedDate}}</small>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div> -->
<div class="d-flex" >
    <div class="header-btn-lg pr-0">
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">
                <div class="widget-content-left">
                    <div class="btn-group" ngbDropdown placement="bottom-right">
                        <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle  [disabled]="emailMessages.length == 0">
                            <span>
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <i class="fa fa-bell"></i><span *ngIf="emailMessages.length != 0"  class="noti-circle">{{emailMessages?.length}}</span>
                                </div>
                            </span>
                        </button>
                        <div ngbDropdownMenu>
                            <div class="notification-widget">
                                <div class="notification-header">
                                    <span>Notifications ({{emailMessages?.length}})</span>
                                    <a class="float-right text-light cursor-pointer" (click)="onMarkAllAsRead()">Mark all as read</a>
                                </div>
                                <div class="notification-content">
                                    <perfect-scrollbar ng-reflect-auto-propagation="true" class="ps-show-limits">
                                        <ul class="notification-list">
                                            <li class="notification-box" *ngFor="let item of emailMessages; let even=even" [ngClass]="{'bg-gray': !even }">
                                                <div class="row message-content">
                                                    <!-- <div class="col-lg-3 col-sm-3 col-3 text-center">
                                                        <img src="./assets/images/avatars/1.jpg" class="w-50 rounded-circle">
                                                    </div> -->
                                                    <div class="col-lg-9 col-sm-9 col-9">
                                                        <strong class="text-info">{{item?.fullName}}</strong>
                                                        <div>
                                                            {{item?.message}}
                                                        </div>
                                                        <small class="text-warning">{{item?.creationTs | date : 'medium'}}</small>
                                                    </div>
                                                    <div class="col-lg-2 col-sm-2 col-2">
                                                        <button class="border-0 btn-transition btn btn-outline-success msg-icon-box"
                                                                (click)="onMarkAsRead(item)" title="Mark as Read">
                                                            <i class="fa fa-check-square-o"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </perfect-scrollbar>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>